import React from 'react'
import { Link } from 'react-router-dom'
import Img1 from '../../assets/images/sobremesas-parmeca.png'
import Img2 from '../../assets/images/brownie-ao-leite.png.png'
import Img3 from '../../assets/images/brownie-com-nozes-muito-bom.png'
import Img4 from '../../assets/images/brownie-duo-foto-imagem.png'
import Img5 from '../../assets/images/brownie-nutella.png'
import Img6 from '../../assets/images/minicups-haagen-dazs.png'
import Img7 from '../../assets/images/brownie-meio-amargo.png'

function Dishlist2() {
    return (
        <>
            <section className="row_am dishList_block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration={1500} >
                            <div className="dish_img dish_left">
                                <img className="img-fluid" src={Img1} alt="img" />
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration={1500} >
                            <div className="dish_list">
                                <ul>
                                    <li>
                                        <div className="img">
                                            <img src={Img2} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Brownie ao Leite - 70g</h4>
                                                </div>
                                                <div className="doted" />
                                            </div>
                                            <div className="description">
                                                <p>
                                                    Massa na proporção certa de chocolate belga ao leite, 
                                                    cobertos com uma casquinha irresistível.
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img src={Img7} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Brownie Meio Amargo - 70g</h4>
                                                </div>
                                                <div className="doted" />
                                            </div>
                                            <div className="description">
                                                <p>
                                                Massa feita com chocolate meio amargo e pedaços de chocolate belga derretidos.
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img src={Img3} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Brownie com Nozes - 70g</h4>
                                                </div>
                                                <div className="doted" />
                                            </div>
                                            <div className="description">
                                                <p>
                                                Massa feita com chocolate belga derretido e pedaços de nozes.
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img src={Img4} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Brownie Duo - 70g</h4>
                                                </div>
                                                <div className="doted" />
                                            </div>
                                            <div className="description">
                                                <p>
                                                Massa dupla: metade de chocolate belga ao leite e metade de chocolate branco. 
                                                Ela ainda é coberta com sua casquinha crocante e gotas de chocolate.
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img src={Img5} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Brownie de Nutella - 70g</h4>
                                                </div>
                                                <div className="doted" />
                                            </div>
                                            <div className="description">
                                                <p>
                                                Massa feita com chocolate meio amargo belga e um arco de Nutella.
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img src={Img6} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Minicups Haagen Dazs</h4>
                                                </div>
                                                <div className="doted" />
                                            </div>
                                            <div className="description">
                                                <p>
                                                    Além dos melhores parmegianas, trouxemos agora para 
                                                    você o melhor sorvete!
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <Link to="https://pedidos.parmeca.com.br" className="btn btn_primary">
                                    PEÇA PELO APP
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Dishlist2