import React from 'react'
import { Link } from 'react-router-dom'
import Menulist1 from '../../assets/images/parmegianas-parmeca.png'
import Menustb1 from '../../assets/images/file-mignon-parmegiana.png'
import Menustb2 from '../../assets/images/parmegiana-file-mignon-suina.png'
import Menustb3 from '../../assets/images/Peito-Frango-Parmegiana.png'
import Menustb4 from '../../assets/images/file-parmegiana-vegetariana.png'
import Menustb5 from '../../assets/images/parmegiana-mais-massa-foto.png'

function DishList() {
  return (
    <>
      <section className="row_am dishList_block">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration={1500} >
              <div className="dish_img dish_left text-center">
                <img className="img-fluid" src={Menulist1} alt="img" />
              </div>
            </div>
            <div className="col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration={1500} >
              <div className="dish_list">
                <ul>
                  <li>
                    <div className="img">
                      <img src={Menustb1} alt="img" />
                    </div>
                    <div className="info">
                      <div className="mainCource">
                        <div className="dishName">
                          <h4>Filé Mignon à Parmegiana</h4>
                        </div>
                        <div className="doted" />
                      </div>
                      <div className="description">
                        <p>
                          Filé mignon empanado na farinha panko (aprox. 150g) + diversas opções de molho e queijo...
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="img">
                      <img src={Menustb2} alt="img" />
                    </div>
                    <div className="info">
                      <div className="mainCource">
                        <div className="dishName">
                          <h4>Filé Mignon Suíno à Parmegiana</h4>
                        </div>
                        <div className="doted" />
                      </div>
                      <div className="description">
                        <p>
                          Filé Mignon Suíno empanado na farinha panko (aprox. 150g) + diversas opções de molho...
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="img">
                      <img src={Menustb3} alt="img" />
                    </div>
                    <div className="info">
                      <div className="mainCource">
                        <div className="dishName">
                          <h4>Peito de Frango à Parmegiana</h4>
                        </div>
                        <div className="doted" />
                      </div>
                      <div className="description">
                        <p>
                          Peito de frango empanado na farinha panko (aprox. 150g) + diversas opções de molho...
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="img">
                      <img src={Menustb4} alt="img" />
                    </div>
                    <div className="info">
                      <div className="mainCource">
                        <div className="dishName">
                          <h4>Vegetariano à Parmegiana</h4>
                        </div>
                        <div className="doted" />
                      </div>
                      <div className="description">
                        <p>
                          Berinjela e/ou abobrinha empanadas na farinha panko (aprox. 180g) + diversas opções de molho...
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="img">
                      <img src={Menustb5} alt="img" />
                    </div>
                    <div className="info">
                      <div className="mainCource">
                        <div className="dishName">
                          <h4>Parmegiana + Massa</h4>
                        </div>
                        <div className="doted" />
                      </div>
                      <div className="description">
                        <p>
                          Parmegiana + Massa do seu jeito: Escolha entre filé mignon, peito de frango...
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
                <Link to="https://pedidos.parmeca.com.br" className="btn btn_primary">
                  PEÇA PELO APP
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default DishList