import React from 'react'
import { Link } from 'react-router-dom'
import Img1 from '../../assets/images/fettuccine.png'
import Img2 from '../../assets/images/rigatoni.png'
import Img3 from '../../assets/images/gnocchi-de-batata.png'
import Img5 from '../../assets/images/massas-parmeca.png'
import Img7 from '../../assets/images/ravioli-verde.png'


function Dishlist1() {
    return (
        <>
            <section className="row_am dishList_block">
                <div className="container">
                    <div className="row flex-column-reverse flex-lg-row">
                        <div className="col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration={1500} >
                            <div className="dish_list">
                                <ul>
                                    <li>
                                        <div className="img">
                                            <img src={Img1} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Fettuccine</h4>
                                                </div>
                                                <div className="doted" />
                                            </div>
                                            <div className="description">
                                                <p>
                                                Massa fresca artesanal feita na manteiga com ervas. O Fettuccine tem formato longo e achatado. Seu nome, que significa "pequenas fitas" em italiano, vem da Sicília.
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img src={Img2} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Rigatoni</h4>
                                                </div>
                                                <div className="doted" />
                                            </div>
                                            <div className="description">
                                                <p>
                                                Massa de grano duro feita na manteiga com ervas. O Rigatoni tem seu formato curto e tubular, ideal para envolver molhos e garantir sabor e textura a cada garfada.
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img src={Img3} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Gnocchi de Batata</h4>
                                                </div>
                                                <div className="doted" />
                                            </div>
                                            <div className="description">
                                                <p>
                                                    Massa artesanal feita à base de batata, tradição que vem desde o tempo do Império Romano.
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img src={Img7} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Ravioli Verde de Búfala e Pesto</h4>
                                                </div>
                                                <div className="doted" />
                                            </div>
                                            <div className="description">
                                                <p>
                                                    Massa fresca artesanal recheada com mussarela de búfala e pesto de manjericão...
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <Link to="https://pedidos.parmeca.com.br" className="btn btn_primary">
                                    Peça pelo App
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration={1500} >
                            <div className="dish_img dish_right">
                                <img className="img-fluid" src={Img5} alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Dishlist1