import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Dishimg from '../../assets/images/Filé-Mignon-Gnocchi.png'
import ImgDish01 from '../../assets/images/file-mingon-a-parmegiana.png'
import ImgDish02 from '../../assets/images/file-mingon-suino-a-parmegiana.png'
import ImgDish03 from '../../assets/images/file-de-frango-a-parmegiana.png'
import ImgDish04 from '../../assets/images/parmegiana-tradicional.png'
import ImgDish05 from '../../assets/images/parmegiana-mais-massa.png'
import ImgDish07 from '../../assets/images/croccante-mais-2-acompanhamentos.png'
import ImgDish08 from '../../assets/images/grelhado-mais-dois-acompanhamentos.png'
import ImgDish09 from '../../assets/images/parmegiana-fiordilatte.png'
import ImgDish10 from '../../assets/images/parmegiana-4queijos.png'
import ImgDish11 from '../../assets/images/vegetariano-e-pasta.png'
import ImgDish15 from '../../assets/images/brownie-de-leite-parmeca.png'
import ImgDish16 from '../../assets/images/brownie-com-nozes.png'
import ImgDish17 from '../../assets/images/brownie-duo-parmeca.png'
import ImgDish18 from '../../assets/images/minicups-haagen-dazs.png'
import ImgDish19 from '../../assets/images/brownie-meio-amargo.png'
import ImgDish24 from '../../assets/images/brownie-nutella.png'
function MenuSection() {

    const [tabMenu, tabActive] = useState({ a: true });


    return (
        <>
            <section className="our_menu_section row_inner_am light_texchur">
                <div className="side_shape">
                    <img className="right_side" src={Dishimg} alt="img" />
                </div>
                <div className="container">
                    <div className="section_title text-center" data-aos="fade-up" data-aos-duration={1500} >
                        <span className="sub_text">Nosso Menu</span>
                        <h2>Os melhores pratos</h2>
                    </div>
                    <ul className="nav nav-tabs" id="myTab" role="tablist" data-aos="fade-up" data-aos-duration={1500} >
                        <li className="nav-item">
                            <Link className={`nav-link ${tabMenu.a && "active"}`}
                                onClick={() => tabActive({ a: true })} id="parmegianas-tab" data-toggle="tab" to="#parmegianas" role="tab" aria-controls="parmegianas" aria-selected="true" >
                                MONTE SEU PARMECA
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${tabMenu.b && "active"}`}
                                onClick={() => tabActive({ b: true })} id="campeoes-tab" data-toggle="tab" to="#campeoes" role="tab" aria-controls="campeoes" aria-selected="false" >
                                PARMEGIANAS CAMPEÕES
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${tabMenu.c && "active"}`}
                                onClick={() => tabActive({ c: true })} id="dochef-tab" data-toggle="tab" to="#dochef" role="tab" aria-controls="dochef" aria-selected="false" >
                                PRATOS DO CHEF
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${tabMenu.d && "active"}`}
                                onClick={() => tabActive({ d: true })} id="sobremesas-tab" data-toggle="tab" to="#sobremesas" role="tab" aria-controls="sobremesas" aria-selected="false" >
                                SOBREMESAS
                            </Link>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent" data-aos="fade-up" data-aos-duration={1500} >

                        <div className={`tab-pane fade ${tabMenu.a && "show active"}`} id="parmegianas" role="tabpanel" aria-labelledby="parmegianas-tab" >
                            <div className="container">
                                <div className="row">
                                <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish01} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Parmegiana de Filé Mignon</h3>
                                                    <p>
                                                        Filé mignon empanado na farinha panko (aprox. 150g) + 
                                                        diversas opções de molho e queijo + 2 de nossos acompanhamentos.{" "}
                                                    </p>
                                                    <span className="price">A partir de R$74,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish02} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Filé Mignon Suíno</h3>
                                                    <p>
                                                        Filé Mignon Suíno empanado na farinha panko (aprox. 150g) 
                                                        + diversas opções de molho e queijo + 2 de nossos acompanhamentos.{" "}
                                                    </p>
                                                    <span className="price">A partir de R$69,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish03} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Peito de Frango</h3>
                                                    <p>
                                                    Peito de frango empanado na farinha panko (aprox. 150g) 
                                                    + diversas opções de molho e queijo + 2 de nossos acompanhamentos.{" "}
                                                    </p>
                                                    <span className="price">A partir de R$59,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish05} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Parmegiana + Massa</h3>
                                                    <p>
                                                    Filé mignon, peito de frango ou opção vegetariana empanados na farinha panko (aprox. 150g) 
                                                    + até 2 queijos gratinados + diversas opções de molho 
                                                    + massa artesanal feita na manteiga com ervas, servida sobre o mesmo molho.{" "}
                                                    </p>
                                                    <span className="price">A partir de R$59,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <Link to="/menu" className="btn btn_primary">
                                        MENU COMPLETO
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className={`tab-pane fade ${tabMenu.b && "show active"}`} id="campeoes" role="tabpanel" aria-labelledby="campeoes-tab" >
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish04} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Parmegiana Tradicional</h3>
                                                    <p>
                                                    Filé mignon, peito de frango ou vegetariano empanados na farinha panko (aprox. 150g)
                                                    + mussarela e parmesão gratinados + molho rústico de tomates San Marzano italianos 
                                                    + arroz e batata palito fritas em óleo de flor de algodão.{" "}
                                                    </p>
                                                    <span className="price">A partir de R$64,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish09} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Parmegiana Fior di Latte</h3>
                                                    <p>
                                                    Filé mignon, peito de frango ou vegetariano empanados na farinha panko (aprox. 150g) 
                                                    + mussarela italiana fresca Fior di Latte gratinada, servida com tomate sweet grape confitado e pesto de manjericão 
                                                    + molho rústico de tomates frescos San Marzano italianos + arroz caprese e batatas ao murro.{" "}
                                                    </p>
                                                    <span className="price">A partir de R$69,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish10} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Parmegiana 4 Queijos</h3>
                                                    <p>
                                                    Filé mignon, peito de frango ou vegetariano empanados na farinha panko (aprox. 150g) 
                                                    + molho cremoso ao queijos + mussarela, parmesão, gorgonzola e Catupiry gratinados 
                                                    + arroz cremoso e batatas canoa fritas em óleo de flor de algodão. {" "}
                                                    </p>
                                                    <span className="price">A partir de R$69,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish11} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Parmegiana e Pasta Vegetariano</h3>
                                                    <p>
                                                    Berinjela e abobrinha empanados na farinha panko (aprox. 150g) + mussarela gratinada 
                                                    + massa Rigatoni italiana + molho rústico de tomates San Marzano importado.{" "}
                                                    </p>
                                                    <span className="price">A partir de R$59,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <Link to="/menu" className="btn btn_primary">
                                        MENU COMPLETO
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className={`tab-pane fade ${tabMenu.c && "show active"}`} id="dochef" role="tabpanel" aria-labelledby="dochef-tab" >
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish07} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Milanesa + 2 Acompanhamentos</h3>
                                                    <p>
                                                    Monte seu Milanesa: Filé mignon, peito de frango ou vegetariano empanado na farinha panko e finalizado com raspas de limão (aprox. 150g) 
                                                    + 2 de nossos acompanhamentos à sua escolha. Acompanha rodelas de limão para refrescar!{" "}
                                                    </p>
                                                    <span className="price">A partir de R$49,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                                                       <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish08} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Grelhado + 2 Acompanhamentos</h3>
                                                    <p>
                                                    Monte seu grelhado: Filé mignon bovino e suíno ou Peito de frango 
                                                    grelhado (aprox. 200g) + 2 de nossos acompanhamentos.{" "}
                                                    </p>
                                                    <span className="price">A partir de R$49,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <Link to="/menu" className="btn btn_primary">
                                        MENU COMPLETO
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className={`tab-pane fade ${tabMenu.d && "show active"}`} id="sobremesas" role="tabpanel" aria-labelledby="sobremesas-tab" >
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish15} alt="Brownie ao Leite" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Brownie ao Leite</h3>
                                                    <p>
                                                        Massa na proporção certa de chocolate belga 
                                                        ao leite, cobertos com uma casquinha irresistível.
                                                    </p>
                                                    <span className="price">R$9,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish16} alt="Brownie com Nozes" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Brownie com Nozes</h3>
                                                    <p>
                                                    Massa feita com chocolate belga derretido e pedaços de nozes.{" "}
                                                    </p>
                                                    <span className="price">R$9,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish19} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Brownie Meio Amargo</h3>
                                                    <p>
                                                    Massa feita com chocolate meio amargo e pedaços de chocolate belga derretidos.{" "}
                                                    </p>
                                                    <span className="price">R$9,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish17} alt="Brownie Duo Parmeca" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Brownie Duo</h3>
                                                    <p>
                                                    Massa dupla: metade de chocolate belga ao leite e metade de chocolate branco. 
                                                    Ela ainda é coberta com sua casquinha crocante e gotas de chocolate.
                                                    </p>
                                                    <span className="price">R$9,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish24} alt="Brownie Duo Parmeca" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Brownie de Nutella</h3>
                                                    <p>
                                                    Massa feita com chocolate meio amargo belga e um arco de Nutella.
                                                    </p>
                                                    <span className="price">R$10,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish18} alt="Minicups Haagen Dazs" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Minicups Haagen Dazs</h3>
                                                    <p>
                                                        Além dos melhores parmegianas, trouxemos 
                                                        agora para você o melhor sorvete!
                                                    </p>
                                                    <span className="price">R$21,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <Link to="/menu" className="btn btn_primary">
                                        MENU COMPLETO
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MenuSection