import React from 'react'
import { Link } from 'react-router-dom'
import DishImg from '../../assets/images/combo-parmeca-mais-massa.png'
import DishImg01 from '../../assets/images/combo-familia-parmegiana.png'
import DishImg02 from '../../assets/images/combo-crocante-empanado.png'


function DiscountSection() {
    return (
        <>
            <section className="discount_section bg_white_textcher row_inner_am">
                <div className="container">
                    <div className="section_title text-center" data-30-top="position: sticky;"
                        data-aos="fade-up" data-aos-duration={1500} style={{ position: "sticky" }}>
                        <span className="sub_text">Aproveite os Descontos</span>
                        <h2>Em nossos pratos</h2>
                    </div>
                    <div className="dish_slides">
                        <div className="dishMain" data-30-top="position: sticky;" style={{ top: 230, position: "sticky" }}>
                            <div className="dish_block">
                                <div className="dish_text">
                                    <div className="text">
                                        <span className="off">40% off</span>
                                        <h3>Combo Parmegiana + Massa Família</h3>
                                        <p>
                                        40% Off - 3 Filés à Parmegiana + Massa do seu jeito: Escolha entre filé mignon, 
                                        peito de frango ou opção vegetariana + diversas opções de molho e queijo + massa 
                                        fresca. Escolha entre: linguini, penne, gnocchi, ravioli recheado ou 
                                        cappelletti de carne.
                                        </p>
                                        <p className="price">
                                            <span className="new_price">A partir de 109,90</span>
                                            <span className="old_price">R$242,80</span>
                                        </p>
                                        <Link to="https://pedidos.parmeca.com.br" className="btn btn_primary">
                                            PEÇA PELO APLICATIVO
                                        </Link>
                                    </div>
                                </div>
                                <div className="dish_img">
                                    <img src={DishImg} alt="img" />
                                </div>
                            </div>
                        </div>
                        <div className="dishMain" data-30-top="position: sticky;" style={{ top: 260, position: "sticky" }}>
                            <div className="dish_block">
                                <div className="dish_text">
                                    <div className="text">
                                        <span className="off">40% off</span>
                                        <h3>Combo Parmegiana Família</h3>
                                        <p>
                                        3 filés à Parmegiana: Peito de frango, filé mignon ou vegetariano empanado na farinha 
                                        panko (aprox. 450g) + diversas opções de molho e queijo à sua escolha + 2 de nossos 
                                        acompanhamentos, para você personalizar cada detalhe do seu parmegiana.
                                        </p>
                                        <p className="price">
                                            <span className="new_price">A partir de R$109,90</span>
                                            <span className="old_price">R$194,70</span>
                                        </p>
                                        <Link to="https://pedidos.parmeca.com.br" className="btn btn_primary">
                                            PEÇA PELO APLICATIVO
                                        </Link>
                                    </div>
                                </div>
                                <div className="dish_img">
                                    <img src={DishImg01} alt="img" />
                                </div>
                            </div>
                        </div>
                        <div className="dishMain" data-30-top="position: sticky;" style={{ top: 290, position: "sticky" }}>
                            <div className="dish_block">
                                <div className="dish_text">
                                    <div className="text">
                                        <span className="off">40% off</span>
                                        <h3>Combo Milanesa Família</h3>
                                        <p>
                                        3 Filés à Milanesa: Peito de frango, filé mignon ou vegetariano empanado 
                                        na farinha panko (aprox. 450g) + 2 de nossos acompanhamentos, assim 
                                        você personaliza cada detalhe do seu prato.
                                        </p>
                                        <p className="price">
                                            <span className="new_price">A partir de R$99,90</span>
                                            <span className="old_price">R$164,70</span>
                                        </p>
                                        <Link to="https://pedidos.parmeca.com.br" className="btn btn_primary">
                                            PEÇA PELO APLICATIVO
                                        </Link>
                                    </div>
                                </div>
                                <div className="dish_img">
                                    <img src={DishImg02} alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DiscountSection