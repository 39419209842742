import React from 'react'
import { Link } from 'react-router-dom'
import Ctn01 from '../../assets/images/molhos-para-parmegiana.png'
import Ctn02 from '../../assets/images/parmegiana-com-queijos-em-cima.png'
import Ctn03 from '../../assets/images/contact_icon.png'
import Ctn04 from '../../assets/images/parmegiana-em-saopaulo-sp.png'
import Ctn05 from '../../assets/images/combo-parmegiana-sao-paulo.png'

function ContactUS() {
  return (
    <>
      <section className="contact_us_section">
        <div className="container">
          <div className="section_title text-center">
            <span className="icon" data-aos="fade-down" data-aos-duration={1500}>
              <img src={Ctn03} alt="img" />
            </span>
            <span className="sub_text mb-1" data-aos="fade-up" data-aos-duration={1500} >
              Fale Conosco!
            </span>
            <h2 data-aos="fade-up" data-aos-duration={1500}>
              Entre em contato conosco
            </h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="radius_img" src={Ctn04} alt="img" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact_box">
                <ul className="contact_listing" data-aos="fade-up" data-aos-duration={1500} >
                  <li>
                    <p className="sub_title">Ligue agora</p>
                    <div className="cnt_block">
                      <Link to="tel:+1132719508">Aclimação: (11) 3207-3737</Link>
                      <span>
                        <i className="icofont-ui-call" />
                      </span>
                    </div>
                    <div className="cnt_block">
                      <Link to="tel:+11976495897">Perdizes: (11) 3873-1000</Link>
                      <span>
                        <i className="icofont-ui-call" />
                      </span>
                    </div>
                    <div className="cnt_block">
                      <Link to="tel:+11976495897">Vila Olímpia: (11) 3845-0080</Link>
                      <span>
                        <i className="icofont-ui-call" />
                      </span>
                    </div>
                  </li>
                  <li>
                    <p className="sub_title">Envie um Email</p>
                    <div className="cnt_block">
                      <Link to="mailto:">contato@parmeca.com.br</Link>
                      <span>
                        <i className="icofont-envelope-open" />
                      </span>
                    </div>
                  </li>
                  <li>
                    <p className="sub_title">Conheça nossos Resturantes</p>
                    <div className="cnt_block">
                      <p>Aclimação: Rua José Getúlio, 375</p>
                      <span>
                        <i className="icofont-location-pin" />
                      </span>
                    </div>
                    <div className="cnt_block">
                      <p>Perdizes: Rua João Ramalho, 1370</p>
                      <span>
                        <i className="icofont-location-pin" />
                      </span>
                    </div>
                    <div className="cnt_block">
                      <p>Vila Olímpia: Rua Alvorada, 436</p>
                      <span>
                        <i className="icofont-location-pin" />
                      </span>
                    </div>
                  </li>
                  <li>
                    <p className="sub_title">Horário de Funcionamento</p>
                    <div className="cnt_block">
                      <p>Segunda - Sábado : 11:00 - 23:00 <br />
                        Domingo : - 11:00 - 20:00</p>
                      <span>
                        <i className="icofont-envelope-open" />
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="map" data-aos="fade-up" data-aos-duration={1500}>
          <h2 className="sub_title">Unidade 01: Aclimação</h2>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d228.5622810028823!2d-46.63446457139618!3d-23.568569328240407!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce59b57a75b87f%3A0xf74b458e5ebaacc8!2sParmeca%20-%20O%20Parmegiana%20%7C%20Aclima%C3%A7%C3%A3o!5e0!3m2!1spt-BR!2sbr!4v1716213459013!5m2!1spt-BR!2sbr"
              height={450} style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
          </div>
          <div className="map" data-aos="fade-up" data-aos-duration={1500}>
          <h2 className="sub_title">Unidade 02: Perdizes</h2>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d228.62511460868748!2d-46.678205856780416!3d-23.532436457210153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce57fafdc6a21d%3A0xf8876c11e7738918!2sR.%20Jo%C3%A3o%20Ramalho%2C%201370%20-%20Perdizes%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2005008-002!5e0!3m2!1spt-BR!2sbr!4v1716213555544!5m2!1spt-BR!2sbr"
              height={450} style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
          </div>
          <div className="map" data-aos="fade-up" data-aos-duration={1500}>
          <h2 className="sub_title">Unidade 03: Vila Olímpia</h2>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.0243848516534!2d-46.679100600000005!3d-23.603458399999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce51f92b5c2e1b%3A0x7893aef4853a08f6!2sParmeca%20%7C%20O%20Parmegiana%20-%20Vila%20Ol%C3%ADmpia!5e0!3m2!1spt-BR!2sbr!4v1736523794599!5m2!1spt-BR!2sbr" 
              height={450} style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactUS